//require('jquery')
//import jQuery from 'jquery';
//import popper from 'popper.js';
//import bootstrap from 'bootstrap';
//require('./modernizr.custom')

// /require('./stripe_form')
//require('./selectize.min')
//require('./script')
import './pagetransitions.js'
import './script.js'
//import './stripe_form.js'




