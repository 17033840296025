'use strict';

Array.prototype.remove = function(v) { this.splice(this.indexOf(v) == -1 ? this.length : this.indexOf(v), 1); }

window.addCommas = function(nStr)
{
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
};

jQuery(function($) {
    var self;

    var obj = {
        items:[],
        sel: '.item-tags',
        selSubscribeBtn:'#subscribe-button',
        selTotalCost:'#total-cost',
        selAddBtn:'.search-item-btn',
        selItems:'#stripe_items',
        selAmount:'#amount',
        selEmail: '#request-email',
        selSubmitEmail: '#email-submit',
        selSubmitEmailForm: '#send-race-email',
        reqBtn: '#reqBtn',
        selDemoForm: '#createDemoAccount',
        selAgreement: '.agreement-checkbox',
        init:function(){
            self = this;
            $(document).on('click', self.sel +' button', function(e){
                var type = $(this).closest('div.search-item-block').data('type');
                var item = $(e.currentTarget).closest('li').attr('data');
                self.deleteItem(item, type);
            });

            $(document).on('click', self.selAddBtn, function(e){
                var type = $(this).closest('div.search-item-block').data('type');
                return self.processClick(e, selControl[type], type);
            });

            $(document).on('change', self.selEmail, function(e) {
                var email = $(e.target).val();
                var filter = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                if (filter.test(email)) {
                    $(self.selSubmitEmail).prop('disabled', false)
                } else {
                    $(self.selSubmitEmail).prop('disabled', true)
                }
            });

            $(document).on('submit', self.selDemoForm, function(e) {
                e.preventDefault();
                var formEl = $(e.target).closest('form');
                var email = formEl.find('input[name="email"]').val();
                var firstName = formEl.find('input[name="firstName"]').val();
                var lastName = formEl.find('input[name="lastName"]').val();
                var password = formEl.find('input[name="password"]').val();
                var repeatPassword = formEl.find('input[name="repeatPassword"]').val();

                if(password != repeatPassword) {
                    toastr.error('Password does not match, please, retype it.', 'Error');
                    return false;
                }

                if(!self.items.length) {
                    toastr.error('Race field is required!', 'Error');
                    return false;
                }

                var data = {
                    races:self.items,
                    user: {
                        firstName: firstName,
                        lastName: lastName,
                        email: email
                    },
                    password:password,
                    rememberMe:true
                };

                toastr.clear(); // hide all previous messages

                $.ajax({
                    url: window.gv.demoAccCreateUrl,
                    data: JSON.stringify(data),
                    dataType: "json",
                    contentType:"application/json; charset=utf-8",
                    type: 'POST',
                    error: function(xhr) {
                        console.log(JSON.stringify(xhr));
                        if(xhr.responseText) {
                            var err = JSON.parse(xhr.responseText);
                            toastr.error(err.message, 'Error');
                        } else {
                            toastr.error('Some error has occurred, please, try again later!', 'Error');
                        }
                    },
                    success: function(res) {
                        self.clear();
                        self.redraw('race');
                        formEl.trigger("reset");
                        formEl.find('button[type="submit"]').attr('disabled', 'disabled');

                        toastr.success(
                            res.message + '<br /><a href="'+ window.gv.demoAccLoginUrl +'" target="_blank" class="btn btn-primary">Continue</a>',
                            'Account Created:',
                            {timeOut: 60000}
                        );

                        setTimeout(function () {
                            document.location.href = window.gv.demoAccLoginUrl;
                        }, 3000)

                    }});

                return false;
            });

            $(document).on('change', self.selAgreement, function(e){
                var btn = $(this).closest("form").find('button[type="submit"]');
                var agree = $(e.target).prop('checked');

                if(agree) {
                    btn.removeAttr('disabled');
                } else {
                    btn.attr('disabled', 'disabled');
                }
            });
        },
        processClick:function(e, control, type){
            e.preventDefault();

            var value = control.getValue();
            var item = Object.values(control.options).find(function(el){
                return el[typeFld[type].value] == value;
            });

            if(!item) return;

            // deny more then 3 elements
            if(self.items.length > 2 && type != 'market') {
                control.clear();  // clear input
                return false;
            }

            obj.addItem(item, type);
            control.clear();  // clear input
            return false;
        },
        addItem:function(item, type){
            if(self.items.indexOf(item) < 0) {
                self.items.push(item);
                var fld = typeFld[type].value;

                self.items.sort(function(a,b) {
                    if (a[fld] > b[fld]) {
                        return 1;
                    }
                    if (a[fld] < b[fld]) {
                        return -1;
                    }
                    return 0;
                });
                self.redraw(type);
            }
        },
        clear:function(){
            while (self.items.length) {
                self.items.pop();
            }
        },
        redraw:function(type){
            $(self.sel +' li').remove();

            var value = typeFld[type].value;
            var label = typeFld[type].label;

            var desc ='';
            for (var i = 0; i < self.items.length; i++) {
                $(self.sel).append('<li data="'+ self.items[i][value] +'">'
                    + self.items[i][label]
                    +'<button class="close"><span>×</span></button>'
                    +'</li>');

                desc += self.items[i][label] + ';';
            }

            var cost = Math.round(self.items.length * self.getItemCost());

            $(self.selTotalCost)
                .html('$' + window.addCommas(cost));

            $(self.selAmount).val(self.items.length)
                .trigger('change'); // amount

            $(self.selItems).val(desc.slice(0, -1)); // station's list
        },
        deleteItem:function(value, type){
            var fld = typeFld[type].value;
            var item = self.items.find(function(el){
                return el[fld] === value;
            });
            self.items.remove(item);
            self.redraw(type);
        },
        getItemCost: function() {
            return window.gv.marketItemCost;
        }
    };

    obj.init();
    window.obj = obj;

    var typeFld = {
        market:{ value : 'name', label : 'name'},
        race :{ value : 'label', label : 'label'}
    };

    var opts = {
        create: false,
        maxItems: '1',
        valueField: typeFld.race.value,
        labelField: typeFld.race.label,
        searchField: typeFld.race.label,
        sortField: {
            field: typeFld.race.label,
            direction: 'asc'
        },
        load: function(query, callback) {
            if(query.trim().length < 2) return callback();

            $.ajax({
                url: window.gv.raceSearchUrl,
                data: { 'term': query },
                dataType: "json",
                type: 'GET',
                error: function() {
                    callback();
                },
                success: function(res) {
                    callback(res);
                }});
        },
        onChange: function(value){
            if(value.length) {
                $(this.$control_input).removeClass('width-100');
            } else {
                $(this.$control_input).addClass('width-100');
            }
        }
    };

    var marketOpts = {
        valueField : typeFld.market.value,
        labelField : typeFld.market.label,
        searchField: typeFld.market.label,
        sortField: {
            field: typeFld.market.label,
            direction: 'asc'
        },
        load: function(query, callback) {
            var result = window.gv.markets.filter(function(item) {
                return item.name.toLowerCase().indexOf(query.toLowerCase()) != -1;
            });

            callback(result);
        }
    };

    //marketOpts = $.extend({}, marketOpts, opts);

    var marketSearch = 'div[data-type="market"] input[data-value="search-item"]';
    var raceSearch = 'div[data-type="race"] input[data-value="search-item"]';

    $(marketSearch).removeClass(); // remove All classes (important)
    $(raceSearch).removeClass(); // remove All classes (important)

    var selControl = {};

    // search
    if( $(raceSearch).length ) {
        var sel = $(raceSearch).selectize(opts);
        selControl.race = sel[0].selectize;
    }

    // search
    if( $(marketSearch).length ) {
        var sel = $(marketSearch).selectize(marketOpts);
        selControl.market = sel[0].selectize;
    }

    $('.selectize-input input').addClass('form-control'); // for placeholders

    $(obj.reqBtn).on('click', function (e) {
        $('#requestDemoDialog').modal('show');
    });

});
